.generalPractitionerList .MuiDataGrid-columnHeader {
  background-color: #f4f6f8;
}

.pointerRow .MuiDataGrid-row:hover {
  cursor: pointer;
}

.providerList .MuiDataGrid-columnHeader {
  background-color: #e9e9e9;
}

.HSPractitionerRole.MuiDataGrid-row {
  background-color: rgba(92, 184, 92, 0.17) !important;
}

.noBorderGrid {
  border: 0 !important;
}

.noBorderGrid .MuiDataGrid-cell {
  border: 0 !important;
}

@keyframes shine {
  0% {
    opacity: 0.6;
    transform: scale(1);
  }
  50% {
    opacity: 1;
    transform: scale(1.1);
    box-shadow: 0 0 20px #fff;
  }
  100% {
    opacity: 0.6;
    transform: scale(1);
  }
}

.shining-icon-button {
  animation: shine 2s infinite ease-in-out;
}
